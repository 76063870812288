import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const FilterAutocomplete = (
 { options, states, setStates, filterName, filterTitle, multiple, inputSx, ...rest },
) => {
  const selectedOptionsInMultipleSelector = () => options.filter(
    (option) => states[filterName]?.includes(option.value),
  );
  const selectedOptionsInSingleSelector = () => options.filter(
    (option) => states[filterName] === option.value,
  )[0];
  
  const getValue = (isMultipleSelector) => {
    if (isMultipleSelector) {
      return selectedOptionsInMultipleSelector();
    }
    return selectedOptionsInSingleSelector() || null;
  };

  const handleOnChange = useCallback((event, value) => {
    if (!value) {
      setStates({
        ...states, [filterName]: null,
      });
    } else if (multiple) {
      setStates({
        ...states, [filterName]: value.map((v) => v.value),
      });
    } else {
      setStates({ ...states, [filterName]: value.value });
    }
  }, [states, filterName, multiple]);

  const handleRenderInput = useCallback((params) => (
    <TextField
      {...params}
      label={filterTitle}
      InputLabelProps={{ sx: { fontWeight: 'bold' } }}
      sx={inputSx}
    />
  ));

  const multipleProps = multiple ? {
    renderOption: (props, option, { selected }) => (
      <li {...props}>
        <Checkbox
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={<CheckBox fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option.label}
      </li>
    ),
    multiple,
    disableCloseOnSelect: multiple,
    disableListWrap: false,
  } : {};

  const autocompleteStyles = multiple ? {} : {
    sx: {
      '& .MuiAutocomplete-inputRoot': {
        fontWeight: 'bold',
      }
    }
  };

  return (
    <Autocomplete
      disablePortal
      options={options}
      value={getValue(multiple)}
      onChange={handleOnChange}
      limitTags={1}
      renderInput={handleRenderInput}
      {...multipleProps}
      {...autocompleteStyles}
      {...rest}
      fullWidth
    />
  );
};

FilterAutocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  states: PropTypes.shape({
    siiStatus: PropTypes.arrayOf(PropTypes.string),
    documentType: PropTypes.arrayOf(PropTypes.number),
    loanedStatus: PropTypes.string,
    creditNoteStatus: PropTypes.arrayOf(PropTypes.string),
    documentFinanceStatus: PropTypes.arrayOf(PropTypes.string),
    availableForFinancing: PropTypes.string,
    allCompanies: PropTypes.bool,
  }).isRequired,
  setStates: PropTypes.func.isRequired,
  filterName: PropTypes.string.isRequired,
  filterTitle: PropTypes.string,
  multiple: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  inputSx: PropTypes.object,
};
FilterAutocomplete.defaultProps = {
  filterTitle: 'Filtro',
  multiple: true,
  inputSx: {},
};

export default FilterAutocomplete;
